import { html, css, LitElement } from 'lit-element';
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js';

import '@material/mwc-checkbox'
import '@material/mwc-formfield'
import '@material/mwc-textarea'
import './fw-site-aside.js'

export const FW_ARTICLE_SAVE = "FW_ARTICLE_SAVE";
export const FW_ARTICLE_CANCEL = "FW_ARTICLE_CANCEL";
export const FW_ARTICLE_DELETE = "FW_ARTICLE_DELETE";
export const FW_ARTICLE_EDIT = "FW_ARTICLE_EDIT";
export const FW_ARTICLE_LOADED = "FW_ARTICLE_LOADED";
export const FW_SITE_CONFIG_LOADED = "FW_SITE_CONFIG_LOADED";
export const FW_SITE_CONFIG_EDIT_CANCELLED = "FW_SITE_CONFIG_EDIT_CANCELLED";
export const FW_FROALA_EDITOR_ON = "_fw.froala.on";
export const FW_FROALA_EDITOR_OFF = "_fw.froala.off";

export const getFuquaCSS = function() {
    return `
            <style>
                    .fw--btn {
                        font-family: Gibson,Arial,sans-serif;
                        font-weight: 500;
                        text-transform: uppercase;
                        letter-spacing: .08em;
                        font-size: .72222em;
                        line-height: 1em;
                        white-space: nowrap;
                        border: solid 3px #978B82;
                        background: #FFF;
                        padding: 15px .96154em 12px;
                        display: inline-block;
                        color: #978B82;
                        transition: all 0.05s ease-in-out 0s;
                        text-decoration: none;
                    }
                    .fw--btn:hover, .fw--btn:active, .fw--btn:focus,
                        button.fw--btn:hover,
                        button.fw--btn:active,
                        button.fw--btn:focus,
                        a.fw--btn:hover,
                        a.fw--btn:active,
                        a.fw--btn:focus {
                            background: #978B82;
                            color: #FFF;
                            text-shadow: 0 0 1px #ddd;
                    }
                    .fw--only {
                        width: 35px; 
                        border: none; 
                        background-color: inherit; 
                        padding-top: 5px; 
                        padding-bottom: 5px;        
                    }
                    .fw--only svg {
                        height: 1.5em;       
                    } 
                    .fw--btn:focus,
                        button.fw--btn:focus,
                        a.fw--btn:focus {
                            outline: thin dotted gray;
                            outline-offset: 2px;
                    }
                    .fw--btn[disabled] {
                        cursor: default;
                        opacity: 0.4;
                    }
                    .fw--btn[disabled]:hover {
                        background: inherit;
                        color: #978B82;
                    }
                    svg:not(:root).svg-inline--fa {
                        overflow: visible;
                    }
                    svg:not(:root).svg-inline--fa {
                        overflow: visible;
                    }
                    .svg-inline--fa.fa-w-11 {
                        width: .6875em;
                    }
                    .svg-inline--fa.fa-w-11 {
                        width: 0.6875em;
                    }
                    .svg-inline--fa {
                        display: inline-block;
                        font-size: inherit;
                        height: 1em;
                        overflow: visible;
                        vertical-align: -.125em;
                    }
                    .svg-inline--fa {
                        display: inline-block;
                        font-size: inherit;
                        height: 1em;
                        overflow: visible;
                        vertical-align: -0.125em;
                    }
            </style>`;
};
export const emitEvent = function(eventName, eventDetails) {
    let event = new CustomEvent( 
                eventName, { 'bubbles': true, 'composed': true, 'detail': eventDetails } );
    document.dispatchEvent(event);
};
export const emitLocalEvent = function(eventName, eventDetails) {
    let event = new CustomEvent( 
                eventName, { 'bubbles': true, 'composed': true, 'detail': eventDetails } );
    this.dispatchEvent(event);
};
export const handleLoadEvent = function(e) {
    if (e.detail && e.detail.id) {
        this.article = e.detail;
    }
};
export const handleConfigLoadEvent = function(e) {
    if (e.detail) {
        this.configuration = e.detail; 
        console.log(this.configuration);
        this.requestUpdate();
    }
};
export const handleEditing = function(e) {
    this.editing = e.detail; 
};
export const isMicroSite = function(node) {
        return node && node.indexOf("/FuquaWorld/Application/Sites") > -1; 
};
export const getSite = function(node) {
    if (node && node.indexOf("/FuquaWorld/Application/Sites") > -1) {
        let val = node.substring(node.indexOf("/FuquaWorld/Application/Sites") + 30);
        if (val.indexOf("/") > -1) {
            val = val.substring(0, val.indexOf("/"));
        }
        return val;
    } 
};
export const getArticlePath = function(article) {
    let p = article.displayPath + '/' + article.name;
    if (p.startsWith("/Company Home")) {
        p = p.substring(13);
    }
    return p;
};

/*
 * FuquaWorld Article Controller Element
 * 
 * If this is a micro-site article, but does not have an .html file at 
 * the end... then add index.html.
 * 
 */
export class FwArticle extends LitElement {

  static get properties() {
    return {
        base: { type: String },
	node: { type: String },
        public: { type: Boolean },
        article: { type: Object },
        configuration: { type: Object },
        editing: { type: Boolean }
    };  
  }
    
  connectedCallback() {
    super.connectedCallback();
  }
  
  firstUpdated(changedProperties) {
    this.fetchArticle();
  }
  
  async fetchArticle() {
	if (this.node) {
            const url = this.base + '/fw/api/content/article/' + this.node;
            const response = await fetch(url, {
                credentials: 'include'
            });
            const json = await response.json();
            if (json) {
                if (json.hasOwnProperty("ok") && json.ok === false) {
                    if (isMicroSite(this.node) && !this.node.endsWith("/index")) {
                        this.node = this.node + "/index";
                        this.fetchArticle();
                    } else {
                        window.location = '/fw/error/404';
                    }
                } else {
                   this.article = json;
                   if (isMicroSite(this.node)) {
                       this.fetchSiteConfig();
                   }
                   emitEvent(FW_ARTICLE_LOADED, this.article);
                }  
            }
	} else {
            // Wait 1 second to send event...
            setTimeout(() => {  emitEvent(FW_ARTICLE_LOADED, {}); }, 1000);
        }
  }
  
  async fetchSiteConfig() {
      if (isMicroSite(this.node)) {
          let urlSlug = this.node.substring(30);
          if (urlSlug.indexOf("/") > -1) { urlSlug = urlSlug.substring(0,urlSlug.indexOf("/")); }
            const url = this.base + '/fw/cms/FuquaWorld/Application/Sites/' + urlSlug + "/configuration.json";
            const response = await fetch(url, {credentials: 'include' });
            const jsonText = await response.text();
            if (jsonText) {
                this.configuration = JSON.parse(jsonText);
                emitEvent(FW_SITE_CONFIG_LOADED, this.configuration); 
            }          
      }   
  }
  
  constructor() {
    super();
    this.base = "";
  }
  
  render() {
	return html``;
  }
  
  createRenderRoot() {
	return this;
  }

}
if (!customElements.get('fw-article')) customElements.define('fw-article', FwArticle);
